<template>
    <div class="pb-5 mb-5" v-if="$store.state.page.title != undefined" @click="test">
        <Hero class="mb-5" />
        <Blackdivider firstline="Benefits of" :secondline="$store.state.page.title" subtitle="Most competant prices"/>
        <Benefits />

        <Blackdivider firstline="Features of" :secondline="$store.state.page.title" subtitle="Most competant prices"/>
        <Features />

        <Pricing />

        <About />

        <Divider firstline="RedArc's" secondline="Packages" subtitle="The Most Competitive Pricings in The World" :first="false"/>
        <Letstalk />
    </div>
</template>

<script>

import Hero from '../components/page/Hero';
import Blackdivider from '../components/common/Blackdivider';
import Divider from '../components/common/Divider';
import Benefits from '../components/page/Benefits';
import Features from '../components/page/Features';
import Pricing from '../components/page/Pricing';
import About from '../components/page/About';
import Letstalk from '../components/home/Letstalk';
import axios from 'axios';

export default {
    components:{
        Hero,
        Blackdivider,
        Divider,
        Benefits,
        Features,
        Pricing,
        About,
        Letstalk
    },
    data(){
        return {
            data:'',
        }
    },
    computed:{
        id:function(){
            return this.$route.params.id
        } 
    },
    watch:{
        id(){
            this.getPageData(this.id)
        }
    },
    methods:{
        test(){
            console.log(this.$route.params.id );
        },
        getPageData(id){
            axios.get(`${apiURL}/technologies/${id}`).then( 
                (response) => {
                    this.data = response.data,
                    this.$store.dispatch('getPageData',this.data);
                }
            ).catch(
                (error) => {
                    console.log(error)
                }
            )
        }
    },
    mounted(){
        this.id = (this.$route.params.id != undefined) ? this.$route.params.id : 1;
        this.getPageData(this.id);
    }
}
</script>