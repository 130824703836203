<template>
    <section class="letstalk mobilepadding">
        <div class="container">
            <div class="row justify-content-center">
                <p class="col-md-8">We'd love to answer any questions you may have. Contact us and discuss your business objectives & we will let you know how we can help along with a Free Quote.</p>
            </div>
            <div class="row d-flex justify-content-center">
                <div class="col-md-8 letstalkhold">
                    <div class="row">
                        <div class="col-md-4">
                            <a href="mailto:info@redarc.in">
                                <div class="letstalkbox">
                                    <img src="assets/images/email.svg" alt="" />
                                    <h5>Write to us</h5>
                                    <p>info@redarc.in</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-4">
                            <a href="skype:redarctech?chat">
                                <div class="letstalkbox">
                                    <img src="assets/images/skype.svg" alt="" />
                                    <h5>Skype us</h5>
                                    <p>redarctech</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-4">
                            <a href="https://wa.me/917006670045">
                                <div class="letstalkbox">
                                    <img src="assets/images/whatsapp.svg" alt="" />
                                    <h5>WhatsApp us</h5>
                                    <p>+91 700 667 0045</p>
                                </div>
                             </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>