<template>
    <div class="modal fade roll-in-left" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content quotecontent">
                <div class="modal-body p-0 d-flex quotebody" v-if="modal === 'open'">
                    <div class="closefar" @click="closeModal()">
                        <i class="far fa-times-circle"></i>
                    </div>
                    <div class="imagefield w-50">
                        <img src="assets/images/modal.png" class="w-100" />
                    </div>
                    <div class="mainform w-50">
                        <div class="formhead">
                            <h1 class="font-weight-bold gettext marginstrict mt-3">Get a Quote</h1>
                            <div class="strip paddingstrict d-flex align-items-center">
                                <p class="m-0 text-white fontbarlowregular">Everybody hates to to wait ! </p>
                            </div>
                            <p class="fontbarlowregular paddingstrict mt-3">Fill in the form below and one of our redarc associate will contact you within 1 hour</p>
                            
                            <div class="custominputbox w-75 marginstrict">
                                <div class="labelrow d-flex align-items-center justify-content-between">
                                    <p class="m-0">Name</p>
                                    <hr class="m-0 hrquote">
                                </div>
                                <div class="actualinput">
                                    <input type="text" placeholder="Enter Your Name" v-model="form.name" class="cstinput">
                                </div>
                            </div>
                            <div class="custominputbox w-75 mt-2 marginstrict">
                                <div class="labelrow d-flex align-items-center justify-content-between">
                                    <p class="m-0">Email</p>
                                    <hr class="m-0 hrquote">
                                </div>
                                <div class="actualinput">
                                    <input type="text" placeholder="Enter Your Email Address" v-model="form.email" class="cstinput">
                                </div>
                            </div>
                            <div class="custominputbox w-75 mt-2 marginstrict">
                                <div class="labelrow d-flex align-items-center justify-content-between">
                                    <p class="m-0">Mobile</p>
                                    <hr class="m-0 hrquote">
                                </div>
                                <div class="actualinput">
                                    <input type="text" placeholder="Enter Mobile Number" v-model="form.mobile" class="cstinput">
                                </div>
                            </div>
                            <div class="custominputbox w-75 mt-2 marginstrict">
                                <div class="labelrow d-flex align-items-center justify-content-between">
                                    <p class="m-0">Message</p>
                                    <hr class="m-0 hrquote">
                                </div>
                                <div class="actualinput">
                                    <textarea type="text" placeholder="Enter Your Message or Project Detail" v-model="form.message" class="cstinput w-100"></textarea>
                                </div>
                            </div>
                            <div class="custominputbox w-75 mt-2 marginstrict border-0 d-flex justify-content-end">
                                <button class="btn btn-md rounded-pill sbmtbtn text-white font-weight-bold" @click="submit()">Submit</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="modal-body p-5 d-flex quotebody" v-else-if="modal === 'success'">
                    <div class="closefar" @click="closeModal()">
                        <i class="far fa-times-circle"></i>
                    </div>
                    <div class="imagefield w-100 slide-in-elliptic-left-bck d-flex flex-column align-items-center">
                        <img src="assets/images/success.svg" class="w-50 mb-5" />
                        <h3>Your Message has been received successfully</h3>
                        <p class="fontbarlowregular">One of our redarc specialist will contact within an hour</p>
                    </div>
                </div>
                <div class="modal-body p-5 d-flex quotebody" v-else>
                    <div class="closefar" @click="closeModal()">
                        <i class="far fa-times-circle"></i>
                    </div>
                    <div class="imagefield w-100 slide-in-elliptic-left-bck d-flex flex-column align-items-center">
                        <img src="assets/images/notify.svg" class="w-50 mb-5" />
                        <h3>There Was an Error in Your Form Submission</h3>
                        <p class="fontbarlowregular primarycolor"><span class="font-weight-bolder">Possible Cause :</span> Please Check All Required Fields are Filled</p>
                        <button class="btn btn-md primarybg text-white" @click="modal = 'open'">Go Back</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data(){
        return {
            apiURL,
            form:{},
            modal:'open'
        }
    },
    methods:{
        closeModal(){
            let modal = document.querySelector('#exampleModal');
            modal.classList.add('slide-out-left');
            setTimeout(() => {
                $('#exampleModal').modal('hide')
                this.modal = "open";
            },1000)
            setTimeout(() => {
                modal.classList.remove('slide-out-left');
            },1200)
        },
        submit(){
            axios.post(`${apiURL}/quotes`,this.form).then(
                () => {
                    this.form = {}
                    this.modal = "success";
                    setTimeout(() => {
                        $('#exampleModal').modal('hide');
                    },5000)
                }
            ).catch(
                () => {
                    this.modal = "failed";
                }
            )
        }
    },
    mounted(){
       
    }
}
</script>

<style>
    .closefar{
        position: absolute;
        top: 20px;
        left: 20px;
        opacity: 0.7;
        cursor:pointer;
    }
    .labelrow > p{
        font-weight: 600;
    }
    .sbmtbtn{
        background:#c81444;
        padding: 6px 40px !important;
    }
    .cstinput{
        border: none;
        outline: none;
        font-size:14px;
    }
    .custominputbox{
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 3px 0px 3px 10px;
    }
    .gettext{
        font-size: 1.5rem;
        margin-bottom: 5px;
    }
    .strip{
        width: 100%;
        height: 29px;
        background: #c81444;
    }
    .quotecontent{
        border-radius: 30px;
    }
    .paddingstrict{
        padding-left: 20px;
    }
    .marginstrict{
        margin-left: 20px;
    }
    .fontbarlowregular{
        font-family: "Barlow";
    }
    .hrquote{
        width:80%;
    }
</style>