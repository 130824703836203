<template>
    <section class="container-fluid heromain">
        <div class="container">
            <div class="row">
                <div class="col-md-6 pt-5 mt-5 rblock">
                    <h1>{{$store.state.page.services[0].title}}</h1>
                    <h4>{{$store.state.page.title}}</h4>
                    <hr>
                    <p>{{$store.state.page.data.heading1}}</p>
                    <div class="checkedinfo d-flex">
                        <img src="assets/images/pinkcheck.png" width="40px" height="40px" class="mr-3" >
                        <p>{{$store.state.page.data.heading2}}</p>
                    </div>
                    <div class="quicknavigator my-5">
                        <div class="naviblock">
                            <div class="navicontent">
                                <p>Go to</p>
                                <p>Benefits</p>
                            </div>
                            <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                        <div class="naviblock">
                            <div class="navicontent">
                                <p>Go to</p>
                                <p>Benefits</p>
                            </div>
                            <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                        <div class="naviblock">
                            <div class="navicontent">
                                <p>Go to</p>
                                <p>Benefits</p>
                            </div>
                            <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                        <div class="naviblock">
                            <div class="navicontent">
                                <p>Go to</p>
                                <p>Benefits</p>
                            </div>
                            <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex">
                    <img :src="`${apiURL}${$store.state.page.image[0].url}`" class="w-100" >
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data(){
        return {
            apiURL,
        }
    }
}
</script>

<style lang="css">
    .quicknavigator .naviblock:last-child{
        border-radius: 0px 15px 15px 0px;
    }
    .quicknavigator .naviblock:first-child{
        border-radius: 15px 0px 0px 15px;
    }
    .navicontent > p:first-child{
        font-weight:900;
    }
    .navicontent > p{
        font-family: 'Barlow';
        color:white;
        margin:0px;
    }
    .quicknavigator{
        display:flex;
        justify-content:space-between;
    }
    .naviblock{
        background:#F50057;
        width:24%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        color:white;
    }
    .rblock{
        background-image: url('../../../public/assets/images/squares.png');
        background-repeat: no-repeat;
        background-size: 150px;
        background-position: top right;
    }
    .heromain{
        background-image: url('../../../public/assets/images/moon.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    @media (max-width: 767px){
        .heromain > .container > .row{
            flex-direction: column-reverse !important;
        }
    }
</style>