<template>
    <section id="fixedfooter">
        <div class="container">
            <div class="row">
                <div class="col-md-8 footicons">
                    <ul>
                        <li><a href="mailto:redarc.technology@gmail.com,info@redarc.in" class="hoverprop"><i class="fas fa-envelope"></i></a></li>
                        <li><a href="" class="hoverprop"><i class="fab fa-skype"></i></a></li>
                        <li><a href="https://wa.me/917006670045" class="hoverprop" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                        <li class="last"><a class="hoverprop" href=""><i class="far fa-comments"></i></a></li>
                        <li class="last"><a class="footbutton" href="#" data-toggle="modal" data-target="#exampleModal"  data-backdrop="static">Get a Quote</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    
    
}
</script>