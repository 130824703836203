<template>
    <section class="reviews">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div id="myCarousel" class="carousel slide" data-ride="carousel">
                        <!-- Carousel indicators -->  
                        <!-- Wrapper for carousel items -->
                        <div class="carousel-inner">
                            <div :class="`item carousel-item active`">
                                <div class="row" v-if="reviews.length > 0">
                                    <div class="col-sm-4" v-for="(review, index) in reviews" :key="index">
                                        <div class="testimonial-wrapper">
                                            <div class="testimonial">{{review.review}}</div>
                                            <div class="media">
                                                <div class="media-left d-flex mr-3">
                                                    <img :src="`${apiURL}${review.dp.url}`" alt="">										
                                                </div>
                                                <div class="media-body">
                                                    <div class="overview">
                                                        <div class="name"><b>{{ review.name }}</b></div>
                                                        <div class="details">{{ review.designation }}</div>
                                                        <div class="star-rating">
                                                            <ul class="list-inline">
                                                                <li class="list-inline-item" v-for="index in Math.floor(review.rating)" :key="index"><i class="fa fa-star"></i></li>
                                                            </ul>
                                                        </div>
                                                    </div>										
                                                </div>
                                            </div>
                                        </div>								
                                    </div>
                                </div>			
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            reviews: [],
            apiURL
        }
    },
    mounted() {
        this.getReviews();
    },
    methods: {
        getReviews(){
            axios.get(`${apiURL}/reviews`).then( 
                (response) => {
                    this.reviews = response.data;
                }
            ).catch(
                (error) => {
                    console.log(error)
                }
            )
        }
    }
}
</script>
<style lang="css" scoped>
    .testimonial-wrapper{
        margin-bottom: 30px !important;
    }
</style>