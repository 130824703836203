<template>
    <section class="skill">
        <div class="container">
            <div class="row">
                <div class="col-md-12">

                </div>
            </div>
            <div class="row">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row skillssection">
                            <div class="col-md-4 skillset">
                                <img src="assets/images/php.png"  width="100px" alt="React Logo">
                                <h4>PHP</h4>
                                <p>Choose PHP package to make your system developed or maintained in PHP (Best language for web development)</p>
                            </div>
                            <div class="col-md-4 skillset">
                                <img src="assets/images/codeigniter.png" width="100px" alt="React Logo">
                                <h4>CodeIgniter</h4>
                                <p>For simple system you can opt for our CodeIgniter package, which is best suited for simple apps.</p>
                            </div>
                            <div class="col-md-4 skillset">
                                <img src="assets/images/laravel.png" width="100px" alt="React Logo">
                                <h4>Laravel</h4>
                                <p>Get your web applications developed in world's no. 1 framework Laravel having 67.8k star ratings.</p>
                            </div>
                        </div>
                        <div class="row skillssection second">
                            <div class="col-md-4 skillset">
                                <img src="assets/images/reactnative.png" width="100px" alt="React Logo">
                                <h4>React Native</h4>
                                <p>Get Your Android And ios Apps Developed With React Native The LightWeight And Modern JS Technology.</p>
                            </div>
                            <div class="col-md-4 skillset">
                                <img src="assets/images/python.png" width="100px" alt="React Logo">
                                <h4>Python</h4>
                                <p>Automate and make your system intelligent by opting for the AI and ML package of python.</p>
                            </div>
                            <div class="col-md-4 skillset">
                                <img src="assets/images/electron.png" width="100px" alt="React Logo">
                                <h4>Electron</h4>
                                <p>Develop out desktop applications for your buisiness in the latest trending technology Electron.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>
<style lang="css" scoped>
    @media (max-width: 767px){
        .skillssection{
            margin-top: -100px !important;
        }
        .skillssection.second{
            margin-top: 0px !important;
        }
    }
</style>