<template>
<!-- Header Start -->
<div class="header-container">
    <header>
        <div class="container">
            <div class="row mb-5 mb-md-0 topline clearfix mt-2 mt-md-0">
                <ul>
                    <li> <i class="fab fa-skype"></i> <a href="#">redarctechnology</a></li>
                    <li> <i class="fas fa-phone-volume"></i> <a href="#">+91-7006670045</a></li>
                    <ul class="d-none d-md-inline-block">
                        <li> <i class="fas fa-circle"></i> <a href="#">Live Chat (Online)</a></li>
                        <li> <i class="fas fa-circle"></i> <a href="#">Live Support (Online)</a></li>
                    </ul>
                </ul>
            </div>
            <div class="container breakpoint">
                <div class="row navholder">
                    <div class="logo" @click.prevent="$router.push('/').catch(()=>{})">
                        <img class="logoimg fixedlogo" src="assets/images/logo.png" alt="Logo">
                        <img id="arc" class="arc d-none d-md-block" src="assets/images/arc.png" alt="Arc">
                    </div>
                    <nav>
                        <ul class="definedheight">
                            <li v-for="(category,index) in data" :key="index"  class="d-none d-md-block">
                                <a href="#" @click.prevent="showMenu(index,false)">{{category.title}}</a>
                            </li>
                            <li  class="d-none d-md-block"><a href="" class="btn d-none d-md-block btn-lg btn-danger topbutton" data-toggle="modal" data-target="#exampleModal"  data-backdrop="static">Get a Quote</a></li>
                            <li class="menu-icon-holder d-block d-md-none">
                                <div class="menu-icon"  @click.prevent="showMenu(0,false)">
                                    <i class="fas fa-align-right"></i>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            
        </div>
    </header>

    <div :class="(menu) ? 'service-header text-white' : 'menu-d-none'">
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-md-3 slide-in-left ">
                    <div class="logo  d-flex justify-content-center py-3">
                        <a href="#" @click.prevent="navigate(0,true)">
                            <img src="assets/images/logo.png" alt="" width="150px" srcset="">
                        </a>    
                    </div>
                    <div class="close-section d-flex h-100 align-items-center justify-content-center">
                        <a href="#" @click.prevent="hidemenu"><i class="fas fa-times"></i></a>
                    </div>
                </div>

                <div class="col-md-9 internalmenu bgorange puff-in-hor">
                    <div class="service-nav py-4 px-4">
                        <ul class="list-unstyled actualmenu m-0 p-0">
                            <li v-for="(category,index) in data" :key="index">
                                <a href="#" @click.prevent="showMenu(index,true)">{{category.title}}</a>
                            </li>
                            <li><a href="" class="footbutton d-none d-md-inline-block ml-5">Get a Quote</a></li>
                        </ul>
                    </div>

                    <div class="service-technologies-continer h-md-100 px-3 mt-4 d-flex align-items-center">
                        <div class="row">
                            <div class="col-md-5">
                                <h3 class="font-weight-bold mt-3 d-md-none d-lg-none d-xl-none mb-4">&bull; {{current.title}}</h3>
                                <ul class="list-unstyled mainmenu p-0 m-0">
                                    <li v-for="(category,index) in current.technologies" :key="index"><a href="#" @click.prevent="navigate(category.id, false)">{{category.title}}</a></li>
                                </ul>

                                <div class="watermark-logo mt-5 d-none d-md-block">
                                    <img src="assets/images/logo-white.png" alt="">
                                </div>
                            </div>

                            <div class="col-md-7 pr-5 pb-5">
                                
                                <div class="service-img">
                                    <img :src="`${apiURL}${current.image.url}`" alt="" class="img-fluid primaryimg" srcset="">
                                </div>
                                <h1 class="font-weight-bold mt-3 d-none d-md-block">{{current.title}}</h1>
                                <p class="service-details d-none d-md-block">{{current.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!-- Header End -->
</template>
 
<script>
import axios from 'axios';
export default {
    data() {
        return {
            apiURL,
            menu:false,
            data:{},
            current:{
                image:{
                    url:''
                }
            }
        }
    },
    mounted() {
        axios.get(`${apiURL}/services?_sort=priority:ASC`).then(
            (data) => {
                this.data = data.data;
                this.$store.dispatch('setNavData',this.data);
            }
        )
    },
    methods: {
        showMenu(index,internal) {
            let servicenav = document.querySelector('.service-nav');
            let othercontainer = document.querySelector('.service-technologies-continer');
            let primaryimg = document.querySelector('.primaryimg');
            if(!internal){
                this.current = this.data[index];
                $("html, body").animate({ scrollTop: 0 }, "slow");
                $('body').css({ 'height': '100vh', 'overflow': 'hidden' });
                this.menu = true
            }else{
                servicenav.classList.add('slide-out-left');
                othercontainer.classList.add('slide-out-left');
                setTimeout(() => {
                    this.current = this.data[index];
                },800)
                setTimeout(() => {
                    servicenav.classList.remove('slide-out-left');
                    othercontainer.classList.remove('slide-out-left');
                    
                    primaryimg.classList.add('roll-in-left');
                    servicenav.classList.add('slide-in-left-additional');
                    othercontainer.classList.add('slide-in-left-additional');
                   
                },1500)
                setTimeout(() => {
                    primaryimg.classList.remove('roll-in-left');
                    servicenav.classList.remove('slide-in-left-additional');
                    othercontainer.classList.remove('slide-in-left-additional');
                },2700)
                
                
            }
            
        },
        hidemenu(){
            let main = document.querySelector('.service-header');
            main.classList.add('slide-out-left');
            setTimeout(()=>{
                $('body').css({ 'height': 'auto', 'overflow': 'unset' });
                this.menu = false
            },1000)
            
        },
        navigate(id,home) {
            if(home){
                this.$router.push(`/`).catch(()=> {});
            }else{
                this.$router.push(`/service/${id}`).catch(()=> {});
            }
            this.hidemenu();
        }
    }
}
</script>

<style lang="css" scoped>
    .definedheight{
        height: 50px !important;
    }
    .menu-icon-holder{
        position: relative;
    }
    .menu-icon{
        position: absolute;
        top: -50px;
        right: 5px;
    }
    .menu-icon svg{
        font-size: 22px;
    }
</style>