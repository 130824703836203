<template>
    <section class="features">
        <div class="container">
            <div class="row">
                <div class="col-md-6 features-bg text-white">
                    <h3 class="bold mb-0">Why {{$store.state.page.title}}?</h3>
                    <hr>
                    <span class="clearfix"></span>
                    <ul class="list-unstyled p-0 m-0 mt-2">
                        <li class="mb-3" v-for="(data,index) in $store.state.page.data.features" :key="index">
                            <div class="d-flex justify-content-start align-items-center">
                                <i class="fas fa-circle mr-3"></i>
                                <p class="m-0">
                                    {{data}}
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="col-md-6 d-flex align-items-center pl-5">
                    <img src="assets/images/feat_common.svg" alt="" srcset="" class="img-fluid my-3">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
 .features-bg{
    width: 100%;
    min-height: 635px;
    background: url('../../../public/assets/images/features.png') no-repeat;
    background-size: contain;
    padding: 70px 30px 100px 40px;
}
.features-bg a{
    font-weight: bold;
    color: #f50057;
}
.features-bg hr{
    width: 100px;
    height: 2px;
    background: white;
    align-self: flex-start;
    float:left;
}
@media (max-width: 767px){
    .features-bg{
        background-size: cover !important;
    }
}
</style>