<template>
    <section class="container-fluid secondarybg pricingsection">
        <div class="container">
            <div class="row">
                <h1 class="primarycolor text-center font-weight-bold priceheading pt-5 w-100">Hire a Dedicated <span class="text-white">{{$store.state.page.title}} Developer</span></h1>
                <p class="text-center text-white w-100">The Most Competitive Pricings in The World</p>
                <div class="pricingstable mt-5">
                    <div class="priceboxwrapper">
                        <div class="pricebox">
                            <div class="mainpricing secondarybg">
                                <i class="fas fa-rupee-sign"></i>
                                <div class="actualtext">
                                    <p>Starting</p>
                                    <h1>1,00,000 <span>Per month</span></h1>
                                </div>
                            </div>
                            <div class="textwithchecks d-flex flex-column">
                                <div>
                                    <img src="assets/images/check_white.png">
                                    <p>21 Working Days</p>
                                </div>
                                <div>
                                    <img src="assets/images/check_white.png">
                                    <p>21 Working Days</p>
                                </div>
                                <div>
                                    <img src="assets/images/check_white.png">
                                    <p>21 Working Days</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="priceboxwrapper">
                        <div class="pricebox">
                            <div class="mainpricing secondarybg">
                                <i class="fas fa-rupee-sign"></i>
                                <div class="actualtext">
                                    <p>Starting</p>
                                    <h1>1,00,000 <span>Per month</span></h1>
                                </div>
                            </div>
                            <div class="textwithchecks d-flex flex-column">
                                <div>
                                    <img src="assets/images/check_white.png">
                                    <p>21 Working Days</p>
                                </div>
                                <div>
                                    <img src="assets/images/check_white.png">
                                    <p>21 Working Days</p>
                                </div>
                                <div>
                                    <img src="assets/images/check_white.png">
                                    <p>21 Working Days</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="priceboxwrapper">
                        <div class="pricebox">
                            <div class="mainpricing secondarybg">
                                <i class="fas fa-rupee-sign"></i>
                                <div class="actualtext">
                                    <p>Starting</p>
                                    <h1>1,00,000 <span>Per month</span></h1>
                                </div>
                            </div>
                            <div class="textwithchecks d-flex flex-column">
                                <div>
                                    <img src="assets/images/check_white.png">
                                    <p>21 Working Days</p>
                                </div>
                                <div>
                                    <img src="assets/images/check_white.png">
                                    <p>21 Working Days</p>
                                </div>
                                <div>
                                    <img src="assets/images/check_white.png">
                                    <p>21 Working Days</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
</template>

<script>
export default {
    
}
</script>

<style lang="css">
    .textwithchecks{
        padding:42px;
    }
    .textwithchecks > div{
        display: flex;
        color:white;
    }
    .mainpricing > svg{
        font-size: 55px;
        margin-right: 10px;
    }
    .mainpricing p {
        margin:0px;
        font-size: 22px;
        line-height:22px;
    }
    .mainpricing{
        display:flex;
        color:white;
        border-radius:15px;
        width:90%;
        align-self:center;
        padding: 10px 17px;
    }
    .actualtext > h1 {
        font-family: 'Barlow';
        font-weight: 900;
        line-height: 35px;
        font-size: 1.7rem;
        margin-bottom:0px;
    }
    .actualtext > h1 > span{
        font-size: 14px;
        font-weight: 500;
    }
    .textwithchecks img{
        width:20px;
        margin-right:12px;
        height:20px;
    }
    .pricingstable{
        display:flex;
        width:100%;
        justify-content: space-between;
        
    }
    .priceboxwrapper{
        background:#DE0350;
        display:flex;
        align-items: center;
        border-radius: 20px;
        width:25%;
    }
    .pricebox{
        background:#F50057;
        height: 85%;
        border-radius: 30px;
        display: flex;
        flex-direction:column;
        width:100%;
        padding: 30px 0px;
    }
    .pricingsection{
        max-height: 375px;
        margin-bottom: 230px;
    }
    .priceheading{
        font-size: 2rem;
    }
    .secondarybg{
        background:#3B3B3B !important;
    }
    .secondarycolor{
        color:#3B3B3B !important;
    }
    .primarybg{
        background:#F50057 !important;
    }
    .primarycolor{
        color: #F50057 !important;
    }

    @media (max-width: 767px){
        .pricingstable{
            flex-direction: column !important;
        }
        .priceboxwrapper{
            width: 100%  !important;
            margin-bottom: 25px;
        }
        .pricingsection{
            max-height: 100% !important;
            margin-bottom: 0px !important;
            padding-bottom: 30px !important;
        }
    }
</style>