<template>
    <section class="benefits">
        <div class="container">
             <div class="row">
                <div class="col-md-6 d-flex align-items-center pr-5">
                    <img :src="`${apiURL}${$store.state.page.image[1].url}`" alt="" srcset="" class="img-fluid my-3">
                </div>

                <div class="col-md-6 benefits-bg text-white">
                    <ul class="list-unstyled p-0 m-0">
                        <li class="mb-3" v-for="(data,index) in $store.state.page.data.benefits" :key="index">
                            <div class="d-flex justify-content-start align-items-center">
                                <img src="assets/images/check_white.png" alt="" srcset="" width="25px" class="mr-3">
                                <p class="m-0">
                                    {{data}}
                                </p>
                            </div>
                        </li>
                    </ul>

                    <a href="#" class="btn btn-md btn-light rounded-pill mt-3 float-right">Get a Quote <i class="fas fa-long-arrow-alt-right ml-2 d-inline-block"></i></a>
                    <span class="clearfix"></span>
                </div>
            </div>
        </div>   
    </section>
</template>

<script>
export default {
    data(){
        return{
            apiURL,
        }
    }
}
</script>

<style scoped>
    .benefits-bg{
        width: 100%;
        min-height: 635px;
        background: url('../../../public/assets/images/benefits.png') no-repeat;
        background-size: contain;
        padding: 70px 30px 100px 40px;
    }
    .benefits-bg a{
        font-weight: bold;
        color: #f50057;
    }
    @media (max-width: 767px){
        .benefits-bg{
            background-size: cover !important;
        }
    }
</style>