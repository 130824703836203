<template>
    <section class="about">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center mb-4">
                    <h2 class="mb-0">Why Choose <span class="text-site-primary">RedArc?</span></h2>
                    <p class="text-secondary small">The Most Competant Prices in the World</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="about-box about-black-box p-3 d-flex justify-content-center align-items-center flex-column">
                        <i class="fas fa-business-time"></i>
                        <span class="heading d-block mt-3">Player Since</span>
                        <span class="detail d-block">2012</span>
                    </div>  
                </div>

                <div class="col-md-3">
                    <div class="about-box about-white-box p-3 d-flex justify-content-center align-items-center flex-column">
                        <i class="fas fa-check-double"></i>
                        <span class="heading d-block mt-3">Projects Done</span>
                        <span class="detail d-block">480+</span>
                    </div>  
                </div>

                <div class="col-md-3">
                    <div class="about-box about-black-box p-3 d-flex justify-content-center align-items-center flex-column">
                        <i class="fas fa-star"></i>
                        <span class="heading d-block mt-3">Rated</span>
                        <span class="detail d-block">5/5</span>
                    </div>  
                </div>

                <div class="col-md-3">
                    <div class="about-box about-white-box p-3 d-flex justify-content-center align-items-center flex-column">
                        <i class="fas fa-headset"></i>
                        <span class="heading d-block mt-3">Support</span>
                        <span class="detail d-block">24x7</span>
                    </div>  
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.about{
    background: #F2F2F2;
    padding: 35px 0px 70px 0px;
}
.about-box{
    width: 100%;
    min-height: 200px;
    border-radius: 15px;
    font-weight: 900;
    font-size: 25px;
    line-height: 1.8rem;
}
.about-box svg{
    font-size: 80px !important;
}
.about-box .detail{ font-family: 'Barlow'; font-size: 30px; font-weight: 600; }
.about-black-box{
    background: #3B3B3B;
} 
.about-white-box{
    background: #FFFFFF;
}
.about-black-box svg, .about-black-box .heading{ color: white; }  
.about-white-box svg{ color: #f50057;}  
.about-black-box .detail{ color: #f50057; }
</style>