<template>
    <section :class="(first) ? 'headings heading-pack' : 'headings'">
        <div class="container">
            <div class="row headrow justify-content-center">
                <div class="col-md-6 col-6 pr-0 pr-md-3 headcontain headleft">
                    <h1 class="bolder">
                        {{firstline}}
                    </h1>
                </div>
                <div class="col-md-6 col-6 headcontain headright">
                    <h1>
                        {{secondline}}
                    </h1>
                </div>
                <hr class="d-none d-md-block">
                    <p>{{subtitle}}</p>
                <hr class="d-none d-md-block">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props:['firstline','secondline','subtitle','first']
}
</script>