<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4 footmain">
                            <div class="logo">
                                <img src="assets/images/logo.png" alt="Redarc Logo" width="250px" />
                            </div>
                            <ul class="firstlist">
                                <li><a href="">ABOUT US</a></li>
                                <li><a href="">CASE STUDIES</a></li>
                                <li><a href="">INVESTORS</a></li>
                                <li><a href="">OUR GITHUB REPO</a></li>
                                <li><a href="">COLLABORATORS</a></li>
                                <li><a href="">CONTACT US</a></li>
                            </ul>
                            <ul class="secondlist">
                                <li><a href="mailto:info@redarc.in,redarc.technology@gmail.com"><i class="fas fa-envelope"></i> info@redarc.in </a></li>
                                <li><a href=""><i class="fab fa-skype"></i> redarctechnology </a></li>
                                <li><a href="https://wa.me/917006670045" target="_blank"><i class="fab fa-whatsapp"></i> +91 7006670045 </a></li>
                                <li>
                                    <ul>
                                        <li><a href="https://facebook.com/redarctech" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://twitter.com/_redarc" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                                        <li><a href="https://instagram.com/_redarc" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-8 footpadder">
                            <div class="row">
                                <div class="col-md-4 col-6">
                                    <div class="footlinks" v-for="(nav, index) in firstcol" :key="index">
                                        <h1 class="head3">{{ nav.title }}</h1>
                                        <ul class="ul1">
                                            <li v-for="(tech, index) in nav.technologies" :key="index"><router-link :to="`/service/${tech.id}`">{{ tech.title }}</router-link></li>
                                        </ul>
                                    </div>
                                    
                                </div>
                                <div class="col-md-4 col-6">
                                    <div class="footlinks" v-for="(nav, index) in secondcol" :key="index">
                                        <h1 class="head3">{{ nav.title }}</h1>
                                        <ul class="ul1">
                                            <li v-for="(tech, index) in nav.technologies" :key="index"><router-link :to="`/service/${tech.id}`">{{ tech.title }}</router-link></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="row d-md-block">
                                        <div class="footlinks col-6 col-md-12" v-for="(nav, index) in thirdcol" :key="index">
                                            <h1 class="head3">{{ nav.title }}</h1>
                                            <ul class="ul1">
                                                <li v-for="(tech, index) in nav.technologies" :key="index"><router-link :to="`/service/${tech.id}`">{{ tech.title }}</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        &copy; {{ new Date().getFullYear() }} Redarc Technology Private Limited. All Rights Reserved
                    </div>
                </div>
            </div>
        </div>
        <Getquote />
    </footer>
</template>

<script>
import Getquote from './Getquote.vue'
export default {
  components: { Getquote },
    data() {
        return {
            firstcol: {},
            secondcol: {},
            thirdcol: {}
        }
    },
    mounted() {
        this.firstcol = this.$store.state.navigation.filter((item, index)=> {
            return (index == 0 || index == 1);
        });
        this.secondcol = this.$store.state.navigation.filter((item, index)=> {
            return (index == 2 || index == 3);
        });
        this.thirdcol = this.$store.state.navigation.filter((item, index)=> {
            return (index == 4 || index == 5);
        });
    },
    methods: {

    }
    
}
</script>

<style lang="css" scoped>
    @media (max-width: 560px) {
        .copyright{
            margin-top: 50px !important;
        }
        .secondlist li{
            max-width: 100% !important;
        }
    }
</style>