<template>
    <section class="services pt-60 pb-60" id="services">
        <div class="container">
           <div class="row">
              <div class="col-md-4 col-sm-12">
                 <div class="single_service service_right">
                    <img src="assets/images/hosting.png" alt="">
                    <h4>Shared Web Hosting</h4>
                    <p>Cheapest Pricings And Services Guaranteed With Unlimited Space Starting $15/ Year.</p>
                 </div>
                 <div class="single_service service_right">
                    <img src="assets/images/server.png" alt="">
                    <h4>Virtual Private Servers</h4>
                    <p>Get Full Control and Root Access of Your Server , Starting only $4/ Month.</p>
                 </div>
                 <div class="single_service service_right">
                    <img src="assets/images/chip.png" alt="">
                    <h4>Dedicated Servers</h4>
                    <p>More Resources Means More Power Get Your Fully Dedicated Server Starting $90</p>
                 </div>
              </div>
              <div class="col-md-4 col-sm-12 text-center">
                 <div class="single_mid mb-5">
                    <img src="assets/images/middle.png" alt="">
                 </div>
              </div>
              <div class="col-md-4 col-sm-12">
                 <div class="single_service">
                    <img src="assets/images/domain.png" alt="">
                    <h4>Domain Registrations</h4>
                    <p>Register Domains from 50 Available Extensions With Pricing As Low As Just $2.</p>
                 </div>
                 <div class="single_service">
                    <img src="assets/images/loupe.png" alt="">
                    <h4>Search Engine Optimization</h4>
                    <p>Get Visible and Top of Google With Our Safe And Secure Search Engine Optimization.</p>
                 </div>
                 <div class="single_service">
                    <img src="assets/images/digital-marketing.png" alt="">
                    <h4>Digital Marketing</h4>
                    <p>Sky Rocket Your Product Sales With Our Unique Digital Marketing Techniques.</p>
                 </div>
              </div>
           </div>
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>