 <template>
    <section class="container-fluid headings heading-black pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center headcontain headleft">
                    <h2 class="bolder font-weight-bold text-site-primary mb-0">
                        {{firstline}} <span class="font-weight-light text-dark">{{secondline}}</span>
                    </h2>
                    <p class="text-dark subtitle-head">{{subtitle}}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props:['firstline','secondline','subtitle']
}
</script>

<style lang="css">
    .heading-black{
        background-image: url('../../../public/assets/images/heading.svg');
        background-repeat:no-repeat;
        background-size:100% 100%;
    }
    .subtitle-head{
        font-size:16px;
    }
</style>