<template>
    <div>
        <Slider />
        <Technologies />
        <Divider firstline="RedArc's" secondline="Packages" subtitle="The Most Competitive Pricings in The World" :first="true" class="d-none d-md-block"/>
        <Pricing class="d-none d-md-block"/>
        <Divider firstline="RedArc's" secondline="Reviews" subtitle="What Client's Think About Us" :first="false"  />
        <Reviews />
        <Divider firstline="RedArc's" secondline="Contact" subtitle="Let's Have a Talk and Discuss" :first="false"/>
        <Letstalk />
        <Divider firstline="RedArc's" secondline="Services" subtitle="Services that We Provide" :first="false"/>
        <Services />
    </div>
</template>
<script>

import Slider from '../components/home/Slider'
import Technologies from '../components/home/Technologies'
import Divider from '../components/common/Divider'
import Pricing from '../components/home/Pricing'
import Reviews from '../components/home/Reviews'
import Letstalk from '../components/home/Letstalk'
import Services from '../components/home/Services'
export default {
    components:{
        Slider,
        Technologies,
        Divider,
        Pricing,
        Reviews,
        Letstalk,
        Services,
    }
}
</script>