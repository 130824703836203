import Vue from 'vue'
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    page: '',
    navigation: {}
  },
  mutations: {
    PAGE_DATA(state,payload) {
      state.page = payload;
    },
    NAV_DATA(state, payload) {
      state.navigation = payload;
    }

  },
  getters: {
    page: (state) => {
      return state.page
    }
  },
  actions: {
    getPageData({commit}, data){
      commit('PAGE_DATA', data)
    },
    setNavData({commit}, data){
      commit('NAV_DATA', data);
    }
  }
})