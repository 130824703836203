<template>
    <section class="pricing">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row mobilepadding">
                        <div class="col-md-3 mb-4">
                            <div class="infoblock">
                                <div class="pricehead infohead">
                                    <h1 class="priceplans">PRICE PLANS</h1>
                                </div>
                                <ul class="zebra">
                                    <li>Techonolgies Used </li>
                                    <li>Development Stops Immediately  </li>
                                    <li>Development Starts Soon After </li>
                                    <li>Application Starts Test After </li>
                                    <li>Development Starts Immediately  </li>
                                    <li>Development Starts Application  </li>
                                    <li>Development Starts Application  </li>
                                </ul>
                                <div class="pricefoot">
            
                                </div>
                                <div class="priceborder infofoot">
                                
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-4">
                            <div class="infoblock">
                                <div class="pricehead website">
                                    <i class="fab fa-php icons"></i>
                                    <h1 class="price">WEBSITE</h1>
                                    <p>DEVELOPMENT</p>
                                </div>
                                <ul class="zebra">
                                    <li>Yes</li>
                                    <li>No</li>
                                    <li>Yes</li>
                                    <li>No</li>
                                    <li>Yes</li>
                                    <li>No</li>
                                    <li>No</li>
                                </ul>
                                <div class="pricefoot">
                                        <p>STARTING</p>
                                        <h1>$ 99 <sub>/ project</sub></h1>
                                        <a href="#" class="btn-price btn-web">GET STARTED</a>
                                </div>
                                <div class="priceborder webfoot">

                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-4">
                            <div class="infoblock">
                                <div class="pricehead mobileapp">
                                    <i class="fas fa-mobile-alt icons"></i>
                                    <h1 class="price">MOBILE</h1>
                                    <p>DEVELOPMENT</p>
                                </div>
                                <ul class="zebra">
                                    <li>Yes</li>
                                    <li>No</li>
                                    <li>Yes</li>
                                    <li>No</li>
                                    <li>Yes</li>
                                    <li>No</li>
                                    <li>No</li>
                                </ul>
                                <div class="pricefoot">
                                    <p>STARTING</p>
                                    <h1>$ 99 <sub>/ project</sub></h1>
                                    <a href="#" class="btn-price btn-mobile">GET STARTED</a>
                                </div>
                                <div class="priceborder mobfoot">
                                
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-4">
                            <div class="infoblock">
                                <div class="pricehead software">
                                    <i class="fas fa-desktop icons"></i>
                                    <h1 class="price">SOFTWARE</h1>
                                    <p>DEVELOPMENT</p>
                                </div>
                                <ul class="zebra">
                                    <li>Yes</li>
                                    <li>No</li>
                                    <li>Yes</li>
                                    <li>No</li>
                                    <li>Yes</li>
                                    <li>No</li>
                                    <li>No</li>
                                </ul>
                                <div class="pricefoot">
                                    <p>STARTING</p>
                                    <h1>$ 99 <sub>/ project</sub></h1>
                                    <a href="#" class="btn-price btn-soft">GET STARTED</a>
                                </div>
                                <div class="priceborder softfoot">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    
}
</script>