<template>
  <div id="app">
    <Header />
    <router-view/>
    <Fixedfooter />
    <Footer v-if="$store.state.navigation.length > 0"/>
  </div>
</template>

<script>
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Fixedfooter from './components/common/Fixedfooter'
export default {
  components:{
    Fixedfooter,
    Footer,
    Header
  }
}
</script>

 